import { on } from 'delegated-events';
import htmx from 'htmx.org';
import createOverlay, {
  createOverlays,
} from '../../components/3-organisms/overlay/overlay';
import invisibleFocus from '../utils/invisibleFocus';

htmx.config.requestClass = 'is-loading';
htmx.config.useTemplateFragments = true;
htmx.config.ignoreTitle = true;

htmx.on('htmx:afterSwap', (event) => {
  createOverlays(event.target);
});

htmx.on('htmx:oobAfterSwap', () => {
  const $overlays = document.getElementById('overlays');

  if ($overlays) {
    createOverlays($overlays);
  }
});

on('mousedown', '[data-hx-trigger="click"]', (event) => {
  const { currentTarget } = event;
  invisibleFocus(currentTarget);
});

htmx.on('htmx:beforeRequest', (event) => {
  const { elt: $trigger } = event.detail;
  invisibleFocus($trigger);

  if ($trigger.dataset.directOverlayUrl) {
    event.preventDefault();
  }
});

document
  .querySelectorAll<HTMLAnchorElement>('a[data-overlay-id]')
  .forEach(($link) => {
    const { overlayId } = $link.dataset;

    if (overlayId) {
      const $overlay = document.getElementById(`overlay-${overlayId}`);

      if ($overlay) {
        const directOverlayUrl = $link.href;
        $link.dataset.directOverlayUrl = directOverlayUrl;
        $link.href = `#overlay-${overlayId}`;

        $link.addEventListener('mousedown', () => {
          $link.href = directOverlayUrl;
        });

        $link.addEventListener('mouseup', () => {
          $link.href = `#overlay-${overlayId}`;
        });

        $link.addEventListener('click', (event) => {
          event.preventDefault();
          const overlay = createOverlay($overlay, false);
          window.history.replaceState(null, '', directOverlayUrl);
          overlay.show();

          overlay.on('hide', () => {
            invisibleFocus($link);
          });
        });
      }
    }
  });
