import { on } from 'delegated-events';

on('click', '.embed__button', (event) => {
  event.preventDefault();

  // Get container
  const { currentTarget: $button } = event;
  const $embed = $button.closest<HTMLElement>('.embed');
  const $code = $embed?.querySelector<HTMLTemplateElement>('.embed__code');
  const $content = $embed?.querySelector<HTMLElement>('.embed__content');

  if (!$code || !$embed) {
    return;
  }

  // Append code
  $content?.remove();
  $embed.appendChild($code.content.cloneNode(true));
});
