import { on } from 'delegated-events';

on('click', '[data-layer-push]', (event) => {
  const { layerPush = '{}' } = event.currentTarget.dataset;

  const dataLayerEvent: DataLayerEvent = {
    event: 'unknown_event',
    eventCategory: undefined,
    eventSubcategory: undefined,
    eventSubcategory2: undefined,
    eventSubcategory3: undefined,
    eventLocation: undefined,
    eventInnerLocation: undefined,
    eventLabel: undefined,
    eventTargetUrl: undefined,
  };

  Object.entries(JSON.parse(layerPush)).forEach(([key, value]) => {
    if (key in dataLayerEvent && value) {
      dataLayerEvent[key as keyof DataLayerEvent] = value;
    }
  });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerEvent);
});
