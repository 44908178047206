import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

on('click', 'summary.filters__filter-summary', (event) => {
  const { currentTarget: $summary } = event;

  invisibleFocus($summary);
});

on('click', '[data-filters-reset]', (event) => {
  const { currentTarget: $reset } = event;
  const $filters = $reset.closest<HTMLFormElement>('.filters');

  // Prevent default reset function
  event.preventDefault();

  // Set invisible focus
  invisibleFocus($reset);

  // Resets all input, textarea, select, checkbox, and radio fields
  $filters
    ?.querySelectorAll<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >('input, textarea, select')
    ?.forEach(($input) => {
      if ($input instanceof HTMLSelectElement) {
        $input.selectedIndex = -1;
      } else if (
        $input instanceof HTMLInputElement &&
        ($input.type === 'checkbox' || $input.type === 'radio')
      ) {
        $input.checked = false;
      } else {
        $input.value = '';
      }
    });

  // Submit form
  $filters?.submit();
});
