import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import moveFocus from '../../../javascripts/utils/moveFocus';

let $activeFocus: HTMLElement | null = null;

const getTrackingCategory = ($element: HTMLElement) => {
  const $base = $element.closest<HTMLElement>('[data-menu-tracking-category]');
  return $base?.dataset.menuTrackingCategory ?? 'menu';
};

on('click', '[data-navigation-link]', (event) => {
  const { currentTarget: $target } = event;
  const { navigationLink = '{}' } = $target.dataset;
  const {
    label = $target.textContent ?? undefined,
    level = 0,
    context = 'n_a',
    position = undefined,
  } = JSON.parse(navigationLink);

  // Get type of click
  let eventSubcategory = 'clickthrough';
  if ($target.hasAttribute('aria-controls')) {
    eventSubcategory =
      $target.getAttribute('aria-expanded') === 'false'
        ? 'level_down'
        : 'level_up';
  }

  // Send tracking information
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nav_menu_interaction',
    eventCategory: getTrackingCategory($target),
    eventSubcategory,
    eventSubcategory2: context,
    eventSubcategory3: `level_${level}`,
    eventLocation: 'text_link',
    eventInnerLocation: position,
    eventLabel: label,
    eventTargetUrl: $target.getAttribute('href') ?? undefined,
  });
});

on('click', '.user-menu__menu-item [aria-controls]', (event) => {
  const { currentTarget: $target } = event;

  event.preventDefault();

  const controls = $target.getAttribute('aria-controls') as string;
  const $panel = document.getElementById(controls);
  const $menu = $target.closest('.user-menu__menu');

  if (!$panel || !$menu) {
    return;
  }

  if ($target.getAttribute('aria-expanded') === 'false') {
    $menu.classList.add('user-menu__menu--with-open-submenu');
    $target.setAttribute('aria-expanded', 'true');
    $panel.setAttribute('aria-hidden', 'false');

    $activeFocus = $target;
    moveFocus($panel);
  } else {
    $target.setAttribute('aria-expanded', 'false');
    $panel.setAttribute('aria-hidden', 'true');
    $menu.classList.remove('user-menu__menu--with-open-submenu');

    if ($activeFocus) {
      moveFocus($activeFocus);
      $activeFocus = null;
    }
  }
});

on('click', '[data-overlay-close="user-menu"]', (event) => {
  const { currentTarget: $target } = event;

  event.preventDefault();

  $target
    .closest('.user-menu__menu-item')
    ?.querySelector<HTMLAnchorElement>('[aria-controls]')
    ?.click();
});

on('overlay:show', '[data-overlay="user-menu"]', (event) => {
  const { currentTarget: $overlay } = event;
  const trackingCategory = 'profile_menu';

  // Send tracking information
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nav_menu_interaction',
    eventCategory: trackingCategory,
    eventSubcategory: 'open',
    eventLabel: `${trackingCategory}_open`,
  });

  // Disable scrolling for every menu panel
  $overlay.querySelectorAll('.user-menu__menu').forEach(($submenu) => {
    disableBodyScroll($submenu);
  });
});

on('overlay:hide', '[data-overlay="user-menu"]', (event) => {
  const { currentTarget: $overlay } = event;

  $overlay.querySelectorAll('.user-menu__menu').forEach(($submenu) => {
    enableBodyScroll($submenu);
  });
});
