import { on } from 'delegated-events';

const TARGET = '.rating__button:not([disabled])';

const onRatingButton = ($ratingButton: HTMLElement) => {
  $ratingButton.classList.add('rating__button--active');

  if ($ratingButton.previousElementSibling) {
    onRatingButton($ratingButton.previousElementSibling as HTMLElement);
  }
};

const offRatingButton = ($ratingButton: HTMLElement) => {
  $ratingButton.classList.remove('rating__button--active');

  if ($ratingButton.previousElementSibling) {
    offRatingButton($ratingButton.previousElementSibling as HTMLElement);
  }
};

on('mouseover', TARGET, (event) => {
  const { currentTarget: $ratingButton } = event;
  onRatingButton($ratingButton);
});

on('mouseout', TARGET, (event) => {
  const { currentTarget: $ratingButton } = event;
  offRatingButton($ratingButton);
});

on('focusin', TARGET, (event) => {
  const { currentTarget: $ratingButton } = event;
  onRatingButton($ratingButton);
});

on('focusout', TARGET, (event) => {
  const { currentTarget: $ratingButton } = event;
  offRatingButton($ratingButton);
});
