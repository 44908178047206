import { on } from 'delegated-events';

on('click', 'button.external-video__overlay-button', (event) => {
  event.preventDefault();

  // Get container
  const { currentTarget: $button } = event;
  const $embed = $button.closest<HTMLElement>('.external-video');
  const $code = $embed?.querySelector<HTMLTemplateElement>(
    '.external-video__code',
  );
  const $content = $embed?.querySelector<HTMLElement>(
    '.external-video__content',
  );

  if (!$code || !$content) {
    return;
  }

  // Delete preview
  $content
    .querySelector('.external-video__preview')
    ?.classList.add('external-video__preview--hidden');

  // Unpack template with content
  $content.appendChild($code.content.cloneNode(true));

  // Find iframe
  const $iframe = $content.querySelector<HTMLIFrameElement>('iframe');

  if ($iframe) {
    const { width, height } = $iframe;
    const ratio = (parseInt(height, 10) / parseInt(width, 10)) * 100;

    $iframe.classList.add('external-video__iframe');
    $content.style.paddingTop = `${ratio}%`;
  }
});

// eslint-disable-next-line import/prefer-default-export
export const destroyExternalVideo = ($externalVideo: HTMLElement) => {
  // Remove all nodes except preview
  const $content = $externalVideo?.querySelector<HTMLElement>(
    '.external-video__content',
  );

  if ($content) {
    [...$content.children].forEach(($child) => {
      if (
        $child instanceof HTMLElement &&
        !$child.matches('.external-video__preview')
      ) {
        $child.remove();
      }
    });
    $content.style.paddingTop = '';
  }

  // Restore preview
  $externalVideo
    .querySelector('.external-video__preview')
    ?.classList.remove('external-video__preview--hidden');
};
