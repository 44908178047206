import { on } from 'delegated-events';

type Filter = { category: string; values: string[]; type: string };

on('submit', '.search-box__search', (event) => {
  const { currentTarget: $form } = event;

  const filters: Filter[] = [];

  const category =
    $form.querySelector('.large-input__label')?.textContent?.trim() ?? '';

  const value =
    $form.querySelector<HTMLInputElement>('.large-input__input')?.value ?? '';

  filters.push({ category, values: [value], type: 'text' });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'list_filter',
    eventCategory: 'Liste',
    eventSubcategory: 'Filter',
    eventLocation: 'search_box',
    eventLabel: 'Filter:Anwenden',
    filters,
  });
});

on('submit', '.search-box__filters', (event) => {
  const { currentTarget: $form } = event;

  const filters: Filter[] = [];

  const $searchTerm = $form.querySelector('.filters__search-term');

  if ($searchTerm) {
    const category =
      $searchTerm.querySelector('.large-input__label')?.textContent?.trim() ??
      '';

    const value =
      $searchTerm.querySelector<HTMLInputElement>('.large-input__input')
        ?.value ?? '';

    filters.push({ category, values: [value], type: 'text' });
  }

  $form.querySelectorAll('.filters__filter').forEach(($filter) => {
    const category =
      $filter.querySelector('.filters__filter-summary')?.textContent?.trim() ??
      '';

    const $$checkboxInput =
      $filter.querySelectorAll<HTMLInputElement>('.checkbox__input');

    const $$radioInput =
      $filter.querySelectorAll<HTMLInputElement>('.radio__input');

    const $dropdown = $filter.querySelector<HTMLSelectElement>(
      '.input__input--select',
    );

    if ($$checkboxInput.length > 0) {
      const values: string[] = Array.from($$checkboxInput)
        .filter(($checkboxInput) => $checkboxInput.checked)
        .map(
          ($checkboxInput) =>
            $checkboxInput.labels?.[0].textContent?.trim() ?? '',
        );

      filters.push({ category, values, type: 'checkbox' });
    } else if ($$radioInput.length > 0) {
      const values: string[] = Array.from($$radioInput)
        .filter(($radioInput) => $radioInput.checked)
        .map(
          ($radioInput) => $radioInput.labels?.[0].textContent?.trim() ?? '',
        );

      filters.push({ category, values, type: 'radio' });
    } else if ($dropdown) {
      const { text } = $dropdown.options[$dropdown.selectedIndex];
      filters.push({ category, values: [text], type: 'dropdown' });
    }
  });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'list_filter',
    eventCategory: 'Liste',
    eventSubcategory: 'Filter',
    eventLocation: 'search_box',
    eventLabel: 'Filter:Anwenden',
    filters,
  });
});

on('overlay:show', '.search-box__filters', (event) => {
  const { currentTarget: $filters } = event;

  $filters
    .querySelectorAll<HTMLInputElement>('[data-sync-from]')
    .forEach(($input) => {
      const $sourceInput = document.querySelector<HTMLInputElement>(
        `[data-sync-id="${$input.dataset.syncFrom}"]`,
      );

      $input.value = $sourceInput?.value ?? '';
    });
});

on('overlay:hide', '.search-box__filters', (event) => {
  const { currentTarget: $filters } = event;

  $filters.querySelector('form')?.reset();
});
