import { FocusableElement, isFocusable } from 'tabbable';

const invisibleFocus = (
  $element: FocusableElement,
  options: FocusOptions = { preventScroll: true },
  force = false,
): void => {
  let notFocusable = false;

  if (force && !isFocusable($element)) {
    notFocusable = true;
    $element.setAttribute('tabindex', '0');
  }

  const onBlur = () => {
    $element.removeEventListener('blur', onBlur);
    $element.classList.remove('has-invisible-focus');

    let $node = $element as HTMLElement;
    let $parent: HTMLElement | null;

    while (
      $node.parentElement !== document.body &&
      // eslint-disable-next-line no-cond-assign
      ($parent = $node.parentElement)
    ) {
      $parent.classList.remove('has-invisible-focus-within');
      $node = $parent;
    }

    if (notFocusable) {
      $element.removeAttribute('tabindex');
    }
  };

  $element.addEventListener('blur', onBlur);
  $element.classList.add('has-invisible-focus');

  let $node = $element as HTMLElement;
  let $parent: HTMLElement | null;

  while (
    $node.parentElement !== document.body &&
    // eslint-disable-next-line no-cond-assign
    ($parent = $node.parentElement)
  ) {
    $parent.classList.add('has-invisible-focus-within');
    $node = $parent;
  }

  $element.focus(options);
};

export default invisibleFocus;
