import { on } from 'delegated-events';

on('click', 'a[data-perserve-between-navigation]', (event) => {
  const { currentTarget: $button } = event;
  const { perserveBetweenNavigation } = $button.dataset;

  const $$targetInputs = perserveBetweenNavigation
    ?.split(',')
    .map((id) => document.getElementById(id))
    .filter(($el): $el is HTMLInputElement => $el instanceof HTMLInputElement);

  $$targetInputs?.forEach(($targetInput) => {
    const $form = $targetInput.form;

    if (!$form || $form.id === '') {
      return;
    }

    sessionStorage.setItem(
      `perserveBetweenNavigation|${$targetInput.id}`,
      $targetInput.value,
    );
  });
});

// Inject and clear after new request
Object.keys(sessionStorage)
  .filter((key) => key.startsWith('perserveBetweenNavigation|'))
  .forEach((key) => {
    const value = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);

    const targetInputId = key.split('|').pop();
    if (targetInputId && value) {
      const $targetInput = document.getElementById(targetInputId);

      if ($targetInput instanceof HTMLInputElement) {
        $targetInput.value = value;
      }
    }
  });
