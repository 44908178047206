import { on } from 'delegated-events';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import moveFocus from '../../../javascripts/utils/moveFocus';
import abort from '../../../javascripts/utils/abort';

const trapStack: FocusTrap[] = [];

on('click', 'button.menu__link[aria-controls]', (event) => {
  const { currentTarget: $button } = event;
  let focusTrap: FocusTrap;

  event.preventDefault();

  const targetId = $button.getAttribute('aria-controls') ?? abort();
  const $target = document.getElementById(targetId) ?? abort();
  const $items = $target.querySelector<HTMLElement>('.menu__items') ?? abort();
  const $overlayBar =
    $button
      .closest<HTMLElement>('.overlay')
      ?.querySelector<HTMLElement>('.overlay__bar') ?? abort();

  $target.hidden = false;
  $target.scroll({ top: 0, behavior: 'instant' });

  $button.setAttribute('aria-expanded', 'true');

  const $back = $target.querySelector<HTMLButtonElement>('.menu__back');
  const $backParent = $back?.parentElement ?? abort();

  const onBack = (backEvent: MouseEvent) => {
    backEvent.preventDefault();
    focusTrap.deactivate();
  };

  focusTrap = createFocusTrap([$items, $backParent, $overlayBar], {
    clickOutsideDeactivates: false,
    allowOutsideClick: true,
    trapStack,
    initialFocus: false,
    returnFocusOnDeactivate: false,
    escapeDeactivates: false,
    onUnpause() {
      $target.scroll({ top: 0, behavior: 'instant' });
    },
    onPostActivate() {
      moveFocus($items);
      disableBodyScroll($target);

      $back?.addEventListener('click', onBack);
    },
    onDeactivate() {
      $button.setAttribute('aria-expanded', 'false');
      $back?.removeEventListener('click', onBack);

      const animation = $target.animate(
        [{ transform: 'translateX(0)' }, { transform: 'translateX(100%)' }],
        { duration: 150 },
      );

      animation.onfinish = () => {
        $target.hidden = true;
      };

      enableBodyScroll($target);
    },
    onPostDeactivate() {
      moveFocus($button);
    },
  });

  focusTrap.activate();
});

on('overlay:show', '.menu', (event) => {
  const { currentTarget: $overlay } = event;
  const trackingCategory = $overlay.dataset.trackingCategory ?? 'menu';

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nav_menu_interaction',
    eventCategory: trackingCategory,
    eventSubcategory: 'open',
    eventLabel: `${trackingCategory}_open`,
  });
});

on('overlay:hide', '.menu', () => {
  trapStack.forEach((focusTrap) => {
    focusTrap.deactivate({
      onPostDeactivate() {
        // Replace re-focus between overlay will do that.
      },
    });
  });
});
