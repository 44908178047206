document
  .querySelectorAll<HTMLElement>('.card-grid--randomize')
  .forEach(($cardGrid) => {
    for (let i = $cardGrid.children.length; i >= 0; i -= 1) {
      const index = Math.floor(Math.random() * (i + 1)); // nosemgrep: nodejs_scan.javascript-crypto-rule-node_insecure_random_generator
      // eslint-disable-next-line security/detect-object-injection
      const $child = $cardGrid.children[index]; // nosemgrep: eslint.detect-object-injection

      if ($child) {
        $cardGrid.appendChild($child);
      }
    }

    const $moreCard = $cardGrid.querySelector('.card-slider__card--more-card');

    if ($moreCard) {
      $cardGrid.appendChild($moreCard);
    }
  });
