import getIndexByLetter from '../../../javascripts/utils/getIndexByLetter';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

enum DropdownAction {
  Close = 0,
  CloseSelect = 1,
  First = 2,
  Last = 3,
  Next = 4,
  Open = 5,
  PageDown = 6,
  PageUp = 7,
  Previous = 8,
  Select = 9,
  Type = 10,
}

class Dropdown {
  $dropdown: HTMLElement;
  $button: HTMLButtonElement;
  $options: HTMLElement;
  $$option: HTMLLabelElement[];
  $selectedOption: HTMLLabelElement | null;
  activeIndex: number | null;
  open = false;
  searchString = '';
  searchTimeout: number | undefined;
  ignoreBlur = false;

  constructor($dropdown: HTMLElement) {
    this.$dropdown = $dropdown;

    const $button =
      $dropdown.querySelector<HTMLButtonElement>('.dropdown__toggle');

    if (!$button) {
      throw new Error('.dropdown__toggle is missing in dropdown');
    }

    const $options = $dropdown.querySelector<HTMLElement>('.dropdown__options');
    if (!$options) {
      throw new Error('.dropdown__options is missing in dropdown');
    }

    this.$button = $button;
    this.$options = $options;

    // Find all options
    this.$$option = [
      ...$options.querySelectorAll<HTMLLabelElement>('[role="option"]'),
    ];

    // Get active option
    const selectedIndex = this.$$option.findIndex(
      ($option) => $option.getAttribute('aria-selected') === 'true',
    );

    this.activeIndex = selectedIndex === -1 ? null : selectedIndex;

    // eslint-disable-next-line security/detect-object-injection
    this.$selectedOption = this.activeIndex
      ? this.$$option[this.activeIndex]
      : null;

    this.init();
  }

  init() {
    // Add aria-roles and add tab index to button
    this.$button.setAttribute('role', 'combobox');
    this.$button.setAttribute('aria-haspopup', 'listbox');
    this.$button.setAttribute('aria-expanded', 'false');
    this.$button.setAttribute('aria-controls', this.$options.id);
    this.$button.setAttribute('tabindex', '0');

    // Add aria-roles and add tab index to options
    this.$options.setAttribute('role', 'listbox');
    this.$options.setAttribute('tabindex', '-1');

    // Add click handlers
    this.$button.addEventListener('blur', this.onComboBlur.bind(this));
    this.$button.addEventListener('click', this.onComboClick.bind(this));
    this.$button.addEventListener('keydown', this.onComboKeyDown.bind(this));

    // Add click handler to options
    this.$$option.forEach(($option, index) => {
      $option.addEventListener('click', (event) => {
        event.preventDefault();
        this.onOptionClick(index);
      });

      $option.addEventListener('mousedown', () => {
        this.onOptionMouseDown();
      });
    });
  }

  getSearchString(char: string) {
    if (typeof this.searchTimeout === 'number') {
      window.clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = window.setTimeout(() => {
      this.searchString = '';
    }, 500);

    this.searchString += char;

    return this.searchString;
  }

  onComboBlur() {
    if (this.ignoreBlur) {
      this.ignoreBlur = false;

      return;
    }

    if (this.open) {
      if (this.activeIndex) {
        this.onOptionChange(this.activeIndex);
      }

      this.updateMenuState(false, false);
    }
  }

  onComboClick() {
    invisibleFocus(this.$button);
    this.updateMenuState(!this.open, false);
  }

  onComboKeyDown(event: KeyboardEvent) {
    const { key } = event;
    const max = this.$$option.length - 1;

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const action = this.#getActionFromKey(event, this.open);

    switch (action) {
      case DropdownAction.Last:
      case DropdownAction.First:
        this.updateMenuState(true);
        break;

      case DropdownAction.Next:
      case DropdownAction.Previous:
      case DropdownAction.PageUp:
      case DropdownAction.PageDown:
        event.preventDefault();

        this.onOptionChange(
          this.#getUpdatedIndex(this.activeIndex ?? -1, max, action),
        );

        break;

      case DropdownAction.CloseSelect:
        event.preventDefault();

        if (this.activeIndex) {
          this.onOptionChange(this.activeIndex);
          this.selectOption(this.activeIndex);
        }

        this.updateMenuState(false);
        break;

      case DropdownAction.Close:
        event.preventDefault();
        this.updateMenuState(false);
        break;

      case DropdownAction.Type:
        this.onComboType(key);
        break;

      case DropdownAction.Open:
        event.preventDefault();
        this.updateMenuState(true);
        break;

      default:
        // Do nothing on other keys
        break;
    }
  }

  onComboType(letter: string) {
    // open the listbox if it is closed
    this.updateMenuState(true);

    // find the index of the first matching option
    const searchString = this.getSearchString(letter);
    const searchIndex = getIndexByLetter(
      this.$$option.map(($option) => $option.innerText),
      searchString,
      (this.activeIndex ?? 0) + 1,
    );

    // if a match was found, go to it
    if (searchIndex >= 0) {
      this.onOptionChange(searchIndex);
    } else {
      window.clearTimeout(this.searchTimeout);
      this.searchString = '';
    }
  }

  onOptionChange(activeIndex: number) {
    this.activeIndex = activeIndex;

    // eslint-disable-next-line security/detect-object-injection
    const $activeOption = this.$$option[activeIndex]; // nosemgrep: eslint.detect-object-injection

    this.$button.setAttribute('aria-activedescendant', $activeOption.id);

    this.$$option.forEach(($option) => {
      $option.classList.toggle(
        'dropdown__option-label--selected',
        $option === $activeOption,
      );
    });
  }

  onOptionClick(activeIndex: number) {
    this.onOptionChange(activeIndex);
    this.selectOption(activeIndex);
    this.updateMenuState(false);
  }

  onOptionMouseDown() {
    this.ignoreBlur = true;
  }

  selectOption(activeIndex: number) {
    // eslint-disable-next-line security/detect-object-injection
    const $activeOption = this.$$option[activeIndex]; // nosemgrep: eslint.detect-object-injection

    this.activeIndex = activeIndex;
    this.$selectedOption = $activeOption;

    const $toggleText = this.$button.querySelector<HTMLElement>(
      '.dropdown__toggle-text',
    );

    if ($toggleText) {
      $toggleText.innerText = $activeOption.innerText;
    }

    this.$$option.forEach(($option) => {
      $option.setAttribute(
        'aria-selected',
        $option === $activeOption ? 'true' : 'false',
      );
    });

    const $radioButton = document.getElementById($activeOption.htmlFor);

    if ($radioButton) {
      ($radioButton as HTMLInputElement).checked = true;
    }
  }

  updateMenuState(open: boolean, callFocus = true) {
    if (this.open === open) {
      return;
    }

    this.open = open;
    this.$button.setAttribute('aria-expanded', open ? 'true' : 'false');
    this.$options.toggleAttribute('hidden', !open);

    const activeId = open ? this.$selectedOption?.id : null;
    this.$button.setAttribute('aria-activedescendant', activeId ?? '');

    if (callFocus) {
      invisibleFocus(this.$button);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  #getUpdatedIndex(
    currentIndex: number,
    maxIndex: number,
    action: DropdownAction,
  ) {
    const pageSize = 10;

    switch (action) {
      case DropdownAction.First:
        return 0;
      case DropdownAction.Last:
        return maxIndex;
      case DropdownAction.Previous:
        return Math.max(0, currentIndex - 1);
      case DropdownAction.Next:
        return Math.min(maxIndex, currentIndex + 1);
      case DropdownAction.PageUp:
        return Math.max(0, currentIndex - pageSize);
      case DropdownAction.PageDown:
        return Math.min(maxIndex, currentIndex + pageSize);
      default:
        return currentIndex;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  #getActionFromKey(
    event: KeyboardEvent,
    menuOpen: boolean,
  ): DropdownAction | null {
    const { key, altKey, ctrlKey, metaKey } = event;
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' '];

    if (!menuOpen && openKeys.includes(key)) {
      return DropdownAction.Open;
    }

    if (key === 'Home') {
      return DropdownAction.First;
    }

    if (key === 'End') {
      return DropdownAction.Last;
    }

    if (
      key === 'Backspace' ||
      key === 'Clear' ||
      (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)
    ) {
      return DropdownAction.Type;
    }

    if (menuOpen) {
      if (key === 'ArrowUp' && altKey) {
        return DropdownAction.CloseSelect;
      }

      if (key === 'ArrowDown' && !altKey) {
        return DropdownAction.Next;
      }

      if (key === 'ArrowUp') {
        return DropdownAction.Previous;
      }

      if (key === 'PageUp') {
        return DropdownAction.PageUp;
      }

      if (key === 'PageDown') {
        return DropdownAction.PageDown;
      }

      if (key === 'Escape') {
        return DropdownAction.Close;
      }

      if (key === 'Enter' || key === ' ') {
        return DropdownAction.CloseSelect;
      }
    }

    return null;
  }
}

document
  .querySelectorAll<HTMLElement>('.dropdown')
  .forEach(($dropdown) => new Dropdown($dropdown));
