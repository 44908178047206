import Tobii from '@midzer/tobii';
import '@midzer/tobii/dist/tobii.min.css';

const tobii = new Tobii({
  selector: '.js-lightbox',
  captions: false,
  closeLabel: 'Vergrößerung schließen',
  loadingIndicatorLabel: 'Bild lädt',
});

export default tobii;
