import { on } from 'delegated-events';

on('click', 'a.js-popup', (event) => {
  event.preventDefault();

  const { currentTarget: $target } = event;
  const { name, title } = $target.dataset;

  // False positive, see https://github.com/nodesecurity/eslint-plugin-security/issues/65
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open($target.href, name ?? title, 'width=600, height=600'); // nosemgrep: eslint.detect-non-literal-fs-filename
});
