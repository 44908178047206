import createSlider from '../../../javascripts/utils/createSlider';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const $$gallery = document.querySelectorAll<HTMLElement>('.gallery');

const updateFigureHeight = ($gallery: HTMLElement) => {
  // Get maximal image size
  const maxHeight = Math.max(
    ...[...$gallery.querySelectorAll<HTMLImageElement>('.image__img')].map(
      ($image) => $image.height,
    ),
  );

  // Set figure height for gallery
  $gallery.style.setProperty('--gallery-figure-height', `${maxHeight}px`);
};

$$gallery.forEach(($gallery) => {
  const $figures = $gallery.querySelector<HTMLDivElement>('.gallery__figures');
  const numberOfFigures =
    $figures?.querySelectorAll('.gallery__figure').length || 0;

  if (!$figures || numberOfFigures <= 1) {
    return;
  }

  updateFigureHeight($gallery);
  $gallery.classList.add('gallery--ready');

  const resizeObserver = new ResizeObserver(() => {
    updateFigureHeight($gallery);
  });

  resizeObserver.observe($gallery);

  const slider = createSlider($figures, '.gallery__figure');

  $gallery
    .querySelector('.gallery__navigation-button--prev')
    ?.addEventListener('click', ({ currentTarget }) => {
      invisibleFocus(<HTMLButtonElement>currentTarget);
      slider.move('prev');
    });

  $gallery
    .querySelector('.gallery__navigation-button--next')
    ?.addEventListener('click', ({ currentTarget }) => {
      invisibleFocus(<HTMLButtonElement>currentTarget);
      slider.move('next');
    });

  $gallery.querySelectorAll('.gallery__position').forEach(($position) => {
    $position.textContent = `1 / ${numberOfFigures}`;

    slider.onUpdate((currentSlide) => {
      $position.textContent = `${currentSlide + 1} / ${numberOfFigures}`;
    });
  });
});
