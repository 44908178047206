import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import { destroyExternalVideo } from '../../2-molecules/external-video/external-video';

const switchTab = ($oldTab: HTMLAnchorElement, $newTab: HTMLAnchorElement) => {
  // Focus to new tab
  invisibleFocus($newTab);

  // Set the selected state
  $newTab.setAttribute('aria-selected', 'true');
  $oldTab.removeAttribute('aria-selected');

  // Hide old panel
  const $oldPanel = document.getElementById($oldTab.hash.slice(1));
  $oldPanel?.setAttribute('aria-hidden', 'true');

  // Destroy external video
  const $externalVideo =
    $oldPanel?.querySelector<HTMLElement>('.external-video');
  if ($externalVideo) {
    destroyExternalVideo($externalVideo);
  }

  // Show old panel
  const $newPanel = document.getElementById($newTab.hash.slice(1));
  $newPanel?.setAttribute('aria-hidden', 'false');
};

on('click', 'a.video-gallery__tab-link', (event) => {
  const { currentTarget: $tab } = event;

  event.preventDefault();

  const $currentTab = $tab
    .closest('.video-gallery__tabs')
    ?.querySelector<HTMLAnchorElement>('[aria-selected]');

  if ($currentTab && $tab !== $currentTab) {
    switchTab($currentTab, $tab);
  }
});
