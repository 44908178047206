import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

on('click', 'button.details__button', (event) => {
  event.preventDefault();

  const { currentTarget: $button } = event;
  const $container = $button.closest('.details');

  $container
    ?.querySelectorAll<HTMLDivElement>('.details__item[aria-hidden="true"]')
    .forEach(($item, index) => {
      $item.removeAttribute('aria-hidden');
      $item.removeAttribute('inert');
      $item.hidden = false;
      $item.classList.remove('details__item--overlay');

      if (index === 0) {
        invisibleFocus($item, {}, true);
      }
    });

  $button.closest('.details__overlay')?.remove();
});
