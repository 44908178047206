import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const switchTab = ($oldTab: HTMLAnchorElement, $newTab: HTMLAnchorElement) => {
  // Focus to new tab
  invisibleFocus($newTab);

  // Set the selected state
  $newTab.setAttribute('aria-selected', 'true');
  $oldTab.removeAttribute('aria-selected');

  // Hide old panel
  const $oldPanel = document.getElementById($oldTab.hash.slice(1));
  $oldPanel?.setAttribute('aria-hidden', 'true');
  $oldPanel?.dispatchEvent(new Event('tabs:hidden'));

  // Show old panel
  const $newPanel = document.getElementById($newTab.hash.slice(1));
  $newPanel?.setAttribute('aria-hidden', 'false');
  $newPanel?.dispatchEvent(new Event('tabs:visible'));
};

on('click', 'a.tabs__tab-link', (event) => {
  const { currentTarget: $tab } = event;

  event.preventDefault();

  const $currentTab = $tab
    .closest('.tabs__tabs')
    ?.querySelector<HTMLAnchorElement>('[aria-selected]');

  if ($currentTab && $tab !== $currentTab) {
    switchTab($currentTab, $tab);
  }
});
