import { on } from 'delegated-events';

on('click', 'button[data-form-action]', (event) => {
  const { currentTarget: $button } = event;
  const { formAction } = $button.dataset;
  const $form = $button.closest('form');

  if ($form) {
    const $actionInput = $form.elements.namedItem('action');

    if ($actionInput instanceof HTMLInputElement) {
      $actionInput.value = formAction ?? '';
    } else {
      const $newActionInput = document.createElement('input');

      $newActionInput.type = 'hidden';
      $newActionInput.name = 'action';
      $newActionInput.value = formAction ?? '';

      $form.prepend($newActionInput);
    }

    event.preventDefault();
    $button.disabled = true;
    $form.submit();
  }
});
