import Draggabilly from 'draggabilly';

class CardSlider {
  $cardSlider: HTMLElement;
  $cards: HTMLElement;
  $scrollBar: HTMLElement;
  $thumb: HTMLElement;
  $leftScrollButton: HTMLElement | null = null;
  $rightScrollButton: HTMLElement | null = null;
  thumbLeft = 0;
  enableScrollbarUpdates = true;
  enableLeftScrollButton = false;
  enableRightScrollButton = false;

  constructor($cardSlider: HTMLElement) {
    this.$cardSlider = $cardSlider;

    const $cards = this.$cardSlider.querySelector<HTMLElement>(
      '.card-slider__cards',
    );

    const $scrollBar = this.$cardSlider.querySelector<HTMLElement>(
      '.card-slider__scroll-bar',
    );

    const $thumb = $scrollBar?.querySelector<HTMLElement>(
      '.card-slider__scroll-bar-thumb',
    );

    if (!$cards || !$scrollBar || !$thumb) {
      throw new Error('Cards and/or scroll bar are missing');
    }

    this.$cards = $cards;
    this.$scrollBar = $scrollBar;
    this.$thumb = $thumb;

    // Init everything
    this.initScrollButtons();
    this.initScrollBar();

    // Force to scroll position to zero on load
    window.requestAnimationFrame(() => {
      this.$cards.scrollTo({ left: 0, behavior: 'instant' });
      this.update();
    });

    // Inside a tab?
    $cardSlider
      .closest('.tabs__panel')
      ?.addEventListener('tabs:visible', () => {
        this.$cards.scrollTo({ left: 0, behavior: 'instant' });
        this.update();
      });
  }

  initScrollBar() {
    // Update on scroll and initial load
    this.$cards.addEventListener('scroll', () => this.update(), {
      passive: true,
    });

    // Update on resizing of scroll bar
    const resizeObserver = new ResizeObserver(() => this.update());
    resizeObserver.observe(this.$scrollBar);

    // Move on backdrop click
    this.$scrollBar
      .querySelector('.card-slider__scroll-bar-backdrop')
      ?.addEventListener('click', (event) => {
        // @ts-expect-error layerX is a non-standard, but well supported attribute on MouseEvent
        const { layerX } = event;

        this.$cards.scrollLeft =
          (layerX / this.$scrollBar.offsetWidth) * this.$cards.scrollWidth;
      });

    const draggie = new Draggabilly(this.$thumb, {
      axis: 'x',
      containment: true,
    });

    draggie.on('dragStart', () => {
      this.enableScrollbarUpdates = false;
      this.$cards.classList.add('card-slider__cards--was-dragged');
    });

    draggie.on('dragMove', (event, pointer, moveVector) => {
      this.$cards.scrollLeft =
        ((this.thumbLeft + moveVector.x) / this.$scrollBar.offsetWidth) *
        this.$cards.scrollWidth;
    });

    draggie.on('dragEnd', () => {
      this.enableScrollbarUpdates = true;
      this.$cards.classList.remove('card-slider__cards--was-dragged');
      this.update();
    });
  }

  initScrollButtons() {
    // Left scroll button
    this.$leftScrollButton = this.$cardSlider.querySelector(
      '.card-slider__navigation-button--left',
    );

    this.$leftScrollButton?.addEventListener('click', (event) => {
      event.preventDefault();
      this.move('left');
    });

    // Right scroll button
    this.$rightScrollButton = this.$cardSlider.querySelector(
      '.card-slider__navigation-button--right',
    );

    this.$rightScrollButton?.addEventListener('click', (event) => {
      event.preventDefault();
      this.move('right');
    });
  }

  move(direciton: 'left' | 'right') {
    const cardWidth =
      this.$cards.querySelector<HTMLElement>('.card-slider__card')?.offsetWidth;

    if (cardWidth) {
      this.$cards.scrollTo({
        left:
          this.$cards.scrollLeft +
          (direciton === 'left' ? cardWidth * -1 : cardWidth),
      });
    }
  }

  update() {
    if (!this.enableScrollbarUpdates) {
      return;
    }

    window.requestAnimationFrame(() => {
      const width = (this.$cards.offsetWidth / this.$cards.scrollWidth) * 100;
      this.thumbLeft =
        (this.$cards.scrollLeft / this.$cards.scrollWidth) *
        this.$scrollBar.offsetWidth;

      this.$thumb.style.setProperty('width', `${width}%`);
      this.$thumb.style.setProperty('left', `${this.thumbLeft}px`);

      this.$scrollBar.classList.toggle(
        'card-slider__scroll-bar--enabled',
        width < 100,
      );

      this.$leftScrollButton?.toggleAttribute(
        'disabled',
        this.$cards.scrollLeft === 0,
      );

      this.$rightScrollButton?.toggleAttribute(
        'disabled',
        this.$cards.scrollWidth <=
          this.$cards.scrollLeft + this.$cards.offsetWidth,
      );
    });
  }
}

document
  .querySelectorAll<HTMLElement>('.card-slider')
  .forEach(($cardSlider) => new CardSlider($cardSlider));

document
  .querySelectorAll<HTMLElement>('.card-slider--randomize')
  .forEach(($cardSlider) => {
    const $cards = $cardSlider.querySelector<HTMLElement>(
      '.card-slider__cards',
    );

    if ($cards) {
      for (let i = $cards.children.length; i >= 0; i -= 1) {
        const index = Math.floor(Math.random() * (i + 1)); // nosemgrep: nodejs_scan.javascript-crypto-rule-node_insecure_random_generator
        // eslint-disable-next-line security/detect-object-injection
        const $child = $cards.children[index]; // nosemgrep: eslint.detect-object-injection

        if ($child) {
          $cards.appendChild($child);
        }
      }

      const $moreCard = $cards.querySelector('.card-slider__card--more-card');

      if ($moreCard) {
        $cards.appendChild($moreCard);
      }
    }
  });

export default CardSlider;
